import { useEffect, useState } from 'react';
import Button from '../../components/Buttons/Button.View';
import CommentItem from '../../components/Comments/Comment.View';
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
import { COLORS, TOAST_POSITION } from '../../constants/BaseStyles';
import {
  ASSET_STATUS,
  CLIENT_TYPE_USER_LIST,
  IFrameSizes,
  INTERNAL_TEAM_MEMBER_LIST,
  STATUS,
} from '../../constants/constant';
import { STRINGS } from '../../constants/labels';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import {
  AssetCommentContainer,
  AssetReviewAndComment,
  AssetStatus,
  AssetStatusContainer,
  AssetsContainer,
  StatusLabel,
  StatusList,
  StatusLogo,
  StatusWrpper,
  VersionList,
  VersionName,
  WebsiteAssetContainer,
  ZoomOutContainer,
  ZoomValue,
} from '../assetsView/Assets.Styles';
import {
  AddVersionBtn,
  BackIconAndName,
  BackIconContainer,
  PagesAndVersion,
  VersionBtn,
  WebSiteName,
  WebsiteHeader,
  WebsiteNameAndStatusContainer,
} from './WebsiteFeeback.Styles';
// assets
import Share from '../../assets/icons/Share.svg';
import Back from '../../assets/icons/Back.svg';
import DropDownIcon from '../../assets/icons/DropDownIcon.svg';
import GoFullscreen from '../../assets/icons/Go_fullscreen.svg';
import Tab from '../../assets/icons/Tab.svg';
import Mobile from '../../assets/icons/Mobile.svg';
import Desktop from '../../assets/icons/Desktop.svg';
import Browse_hamburger from '../../assets/icons/Browse_hamburger.svg';
import Comment from '../../assets/icons/Comments.svg';
//
import { WebpageCreators } from './store/action';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Icon,
  IconsContainers,
  ResponsiveContainer,
  ResponsiveIconContainer,
  WebsiteReviewContainer,
} from './WebsiteReview.Styles';
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from '../../services/toast/toast';
import UniversalModal from '../../components/modals/UniversalModal';
import AddUrlForm from '../../components/form/AddUrlForm.View';
import { Creators } from '../projects/store/action';
import WebsiteIFrame from './WebsiteIFrame.View';
import { findDevice } from '../../utils/utility';

const WebsiteReview = () => {
  const string = STRINGS['ENGLISH'];
  const [comment, setComment] = useState('');
  const param = useParams();
  const navigate = useNavigate();
  const { project_id, project_path } = param;
  const dispatch = useDispatch();
  const [isUpdateReview, setIsUpdateReview] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isUpdateVersion, setIsUpdateVersion] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showComment, setShowComment] = useState(true);
  const [pageUrl, setPageUrl] = useState('');
  const [iframeWidth, setIframeWidth] = useState(IFrameSizes['DESKTOP'].width);
  const [iframeHeight, setIframeHeight] = useState(
    IFrameSizes['DESKTOP'].height
  );
  //
  const [searchParams] = useSearchParams();
  const webpage_id = searchParams.get('webpage_id');
  const version_id = searchParams.get('version_id');
  //   const project_id = searchParams.get('project_id');
  const { userData } = useSelector((state) => state.loggedinUser);
  const { commentsData: commentsDataObj, commentMetaData } = useSelector(
    (state) => state.webpageFeedback
  );
  const {
    // commentsData,
    // webpagesListData,
    singleWebpageData: singleWebpageDataObj,
    // scrapeWebpageData,
    // loading,
    webpageVersions,
  } = useSelector((state) => state.webpageFeedback);
  const singleWebpageData = singleWebpageDataObj[version_id];

  const commentsData = commentsDataObj?.map((data, index) => {
    return {
      ...data,
      comment_no: index + 1,
    };
  });
  //
  const onComment = (parent_comment_id) => {
    if (!comment) {
      ErrorToast('Write something to comment');
      return;
    }

    // console.log(iframeWidth, iframeHeight, IFrameSizes, 'here');
    const viewType = findDevice(iframeWidth, iframeHeight, IFrameSizes);
    const data = {
      webpage_id: webpage_id,
      webpage_version_id: version_id,
      project_id: project_id,
      comments: comment,
      is_reply: parent_comment_id ? true : false,
      ref: 'project',
      ref_id: project_id,
      tagged_usernames: [],
      meta: {},
      created_by_username: userData?.username,
      last_updated_by_username: userData?.username,
      is_resolved: false,
      view_type: viewType,
    };
    if (parent_comment_id) {
      data['parent_comment_id'] = parent_comment_id;
    }
    dispatch(WebpageCreators.addWebsiteComment(data));

    oncancelComment();
  };
  const oncancelComment = () => {
    setComment('');
  };
  useEffect(() => {
    dispatch(
      WebpageCreators.getWebpage({ webpage_id, version_id, project_id })
    );
    //
    dispatch(WebpageCreators.getWebpageVersions({ webpage_id, project_id }));
    const viewType = findDevice(iframeWidth, iframeHeight, IFrameSizes);
    dispatch(
      WebpageCreators.getCommentsByWebsite({
        webpage_id,
        version_id,
        project_id,
        view_type: viewType,
      })
    );
  }, [dispatch, project_id, version_id, webpage_id]);
  //
  const handleChangeVersion = (data) => {
    const { version_id, webpage_id } = data;
    navigate(
      `/${project_path}/${project_id}/website_review?webpage_id=${webpage_id}&version_id=${version_id}`
    );
    setIsUpdateVersion(false);
  };
  //
  const copyToClip = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    SuccessToast(string.COPY_URL, TOAST_POSITION.TOP_CENTER);
  };
  //

  const goBack = () => {
    navigate(
      `/${project_path}/${project_id}/pages?project_name=${singleWebpageData?.project_name}`
    );
  };
  //
  const updateStatus = (status) => {
    if (
      singleWebpageData?.status === 'APPROVED' &&
      (CLIENT_TYPE_USER_LIST.includes(userData?.type) ||
        CLIENT_TYPE_USER_LIST.includes(userData?.user_organisation_role))
    ) {
      WarningToast(
        'Assets are approved for production connect with Panorbit !',
        TOAST_POSITION.TOP_CENTER
      );
      return;
    } else if (
      singleWebpageData?.status === 'APPROVED' &&
      INTERNAL_TEAM_MEMBER_LIST.includes(userData?.user_organisation_role)
    ) {
      WarningToast(
        'Assets are approved for production connect with Admin/Brand Manager',
        TOAST_POSITION.TOP_CENTER
      );
      return;
    }
    dispatch(
      WebpageCreators.updateWebStatus({
        status,
        webpage_id,
        version_id,
        project_id,
      })
    );
  };
  const closeModal = () => {
    setIsOpenModal(false);
    setPageUrl('');
  };
  const addWebsiteUrl = () => {
    if (userData?.username) {
      dispatch(
        Creators.addWebpages({
          url: pageUrl,
          projectId: project_id,
          username: userData?.username,
          webpage_id,
        })
      );
    }
    closeModal();
  };

  const updateFrameSize = (frameSize) => {
    if (iframeWidth !== frameSize.width) {
      setIframeWidth(frameSize.width);
      setIframeHeight(frameSize.height);
    }
    const viewType = findDevice(frameSize.width, frameSize.height, IFrameSizes);
    dispatch(
      WebpageCreators.getCommentsByWebsite({
        webpage_id,
        version_id,
        project_id,
        view_type: viewType,
      })
    );
  };

  const viewFullScreenClick = () => {
    const fullscreenDiv = document.getElementById('fullscreenDiv');
    try {
      if (
        !document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // current working methods
        if (fullscreenDiv.requestFullscreen) {
          setIsFullScreen(true);
          fullscreenDiv.requestFullscreen();
        } else if (fullscreenDiv.mozRequestFullScreen) {
          /* Firefox */
          setIsFullScreen(true);
          fullscreenDiv.mozRequestFullScreen();
        } else if (fullscreenDiv.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          setIsFullScreen(true);
          fullscreenDiv.webkitRequestFullscreen();
        } else if (fullscreenDiv.msRequestFullscreen) {
          /* IE/Edge */
          setIsFullScreen(true);
          fullscreenDiv.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          setIsFullScreen(false);
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          setIsFullScreen(false);
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          setIsFullScreen(false);
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          setIsFullScreen(false);
          document.msExitFullscreen();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AssetsContainer>
      <WebsiteHeader>
        <WebsiteNameAndStatusContainer>
          {/* <---------------page name and status-----------------> */}
          <BackIconAndName>
            <BackIconContainer
              onClick={() => {
                goBack();
              }}
            >
              <DynamicSVGIcon iconUrl={Back} width="1rem" />
            </BackIconContainer>
            <WebSiteName>{singleWebpageData?.webpage_name}</WebSiteName>
            <AssetStatusContainer>
              <AssetStatus
                bg={singleWebpageData?.status}
                onClick={() => {
                  setIsUpdateReview(true);
                }}
              >
                {STATUS[singleWebpageData?.status]}
              </AssetStatus>
              <>
                {isUpdateReview && (
                  <UseClickOutside
                    onClickOutside={() => {
                      setIsUpdateReview(false);
                    }}
                  >
                    <StatusList>
                      {ASSET_STATUS.map((data) => {
                        return (
                          <StatusWrpper
                            onClick={() => {
                              updateStatus(data.value);
                              setIsUpdateReview(false);
                            }}
                          >
                            <StatusLogo>
                              <DynamicSVGIcon
                                iconUrl={data?.img}
                                width="0.6rem"
                              />
                            </StatusLogo>
                            <StatusLabel>{data.label}</StatusLabel>
                          </StatusWrpper>
                        );
                      })}
                    </StatusList>
                  </UseClickOutside>
                )}
              </>
            </AssetStatusContainer>
          </BackIconAndName>
          {/* <-----------------pages version and share-----------------> */}
          <PagesAndVersion>
            <VersionBtn>
              <Button
                text={singleWebpageData?.version_name}
                width={100}
                height={35}
                bgColor={COLORS.BTN_PRIMARY}
                opacity={1}
                fontSize={'0.8rem'}
                rightIcon={DropDownIcon}
                btnClick={() => {
                  setIsUpdateVersion(true);
                }}
              />
              <>
                <UseClickOutside
                  onClickOutside={() => {
                    setIsUpdateVersion(false);
                  }}
                >
                  {isUpdateVersion && (
                    <VersionList>
                      {webpageVersions?.map((data) => {
                        return (
                          <VersionName
                            onClick={() => {
                              handleChangeVersion(data);
                            }}
                          >
                            {data?.version_name}
                          </VersionName>
                        );
                      })}
                    </VersionList>
                  )}
                </UseClickOutside>
              </>
            </VersionBtn>
            <shareBtn>
              <Button
                btnClick={() => {
                  copyToClip();
                }}
                text={''}
                width={35}
                height={35}
                bgColor={COLORS.BTN_PRIMARY}
                opacity={1}
                fontSize={'0.8rem'}
                paddingleft={'0px'}
                rightIcon={Share}
                rightIconrotation={0}
                iconSize={'1rem'}
              />
            </shareBtn>
          </PagesAndVersion>
          {/*<------------------Add version-----------------------------> */}
          <AddVersionBtn
            show={
              !CLIENT_TYPE_USER_LIST.includes(userData?.type) &&
              singleWebpageData?.status !== 'APPROVED'
            }
          >
            <Button
              text={string.ADD_VERSION}
              width={120}
              height={35}
              bgColor={COLORS.BTN_GRADIENT}
              opacity={1}
              fontSize={'0.8rem'}
              btnClick={() => {
                setIsOpenModal(true);
              }}
            />
          </AddVersionBtn>
        </WebsiteNameAndStatusContainer>
      </WebsiteHeader>
      {/* <-----------------------asset review and versioning------------------> */}
      <AssetReviewAndComment>
        {/* <---------------------------------------------------------> */}
        <WebsiteReviewContainer>
          <WebsiteAssetContainer style={{}} id="fullscreenDiv">
            <WebsiteIFrame
              singleWebpageData={singleWebpageData}
              iframeWidth={iframeWidth}
              iframeHeight={iframeHeight}
              webpage_id={webpage_id}
              version_id={version_id}
              project_id={project_id}
              isFullScreen={isFullScreen}
              showComment={showComment}
              commentsData={commentsData?.filter((data) => data?.meta)}
              view_type={findDevice(iframeWidth, iframeHeight, IFrameSizes)}
            />
            <ResponsiveContainer>
              <IconsContainers>
                <ResponsiveIconContainer>
                  <Icon
                    active={IFrameSizes['MOBILE'].width === iframeWidth}
                    onClick={() => updateFrameSize(IFrameSizes['MOBILE'])}
                  >
                    <DynamicSVGIcon iconUrl={Mobile} height="1.6rem" />
                  </Icon>

                  <Icon
                    active={IFrameSizes['TAB'].width === iframeWidth}
                    onClick={() => updateFrameSize(IFrameSizes['TAB'])}
                  >
                    <DynamicSVGIcon iconUrl={Tab} height="1.6rem" />
                  </Icon>
                  <Icon
                    active={IFrameSizes['DESKTOP'].width === iframeWidth}
                    onClick={() => updateFrameSize(IFrameSizes['DESKTOP'])}
                  >
                    <DynamicSVGIcon iconUrl={Desktop} height="1.6rem" />
                  </Icon>
                  {/* <------------------------------------> */}
                </ResponsiveIconContainer>
                {/* >==============================================> */}
                <ResponsiveIconContainer>
                  <Icon
                    active={!showComment}
                    onClick={() => setShowComment(false)}
                  >
                    <DynamicSVGIcon
                      iconUrl={Browse_hamburger}
                      height="1.6rem"
                    />
                  </Icon>
                  <Icon
                    active={showComment}
                    onClick={() => setShowComment(true)}
                  >
                    <DynamicSVGIcon iconUrl={Comment} height="1.6rem" />
                  </Icon>
                  <Icon onClick={viewFullScreenClick}>
                    <DynamicSVGIcon iconUrl={GoFullscreen} height="1.6rem" />
                  </Icon>
                </ResponsiveIconContainer>
              </IconsContainers>
            </ResponsiveContainer>
          </WebsiteAssetContainer>
        </WebsiteReviewContainer>
        {/* <------------------------------comment section------------------------> */}
        <AssetCommentContainer>
          <CommentItem
            comments={commentsData}
            comment={comment}
            setComment={setComment}
            onComment={onComment}
            oncancelComment={oncancelComment}
            website={true}
            commentMetaData={commentMetaData}
            view_type={findDevice(iframeWidth, iframeHeight, IFrameSizes)}
          />
        </AssetCommentContainer>
      </AssetReviewAndComment>
      {/* <-----------------------modal for upload new asset version----------------------> */}
      <UniversalModal
        width={'600px'}
        height={'320px'}
        isOpenModal={isOpenModal}
        btn={true}
        leftText={string.CANCEL}
        rightText={string.ADD}
        onrightbtnClick={addWebsiteUrl}
        onleftbtnClick={closeModal}
      >
        <AddUrlForm pageUrl={pageUrl} setPageUrl={setPageUrl} />
      </UniversalModal>
    </AssetsContainer>
  );
};
export default WebsiteReview;
