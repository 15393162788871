import React, { Fragment, useEffect, useState } from 'react';
import { PinButton } from './CommentPins.Style';

import {
  ButtonRow,
  CommentsInner,
  CommentsWrapper,
  DeleteButton,
  SaveButton,
  TextDiv,
} from './Comments.Style';

import Plus from '../../assets/icons/Plus.svg';
import { STRINGS } from '../../constants/labels';
import { useDispatch, useSelector } from 'react-redux';
import { AssetVersionsCreators } from '../assetVersions/store/action';
import { WebpageCreators } from './store/action';
import { Button } from '../../constants/GlobalStyles.Styles';
import { COLORS } from '../../constants/BaseStyles';

const CommentPins = ({
  coordinates,
  currentBtnCordinate,
  setCommentBoxVisible,
  isCommentBoxVisible,
  setActiveCommentID,
  activeCommentID,
  deleteComment,
  webpage_id,
  version_id,
  project_id,
  scaleFactor = 1,
  viewType,
}) => {
  const dispatch = useDispatch();

  //states
  const [commentVisibility, setCommentVisibility] = useState({});
  const [inputComment, setInputComment] = useState();
  const [activeComment, setActiveComment] = useState(null);

  //store
  const { commentsData } = useSelector((state) => state.webpageFeedback);
  const { highlightComment } = useSelector((state) => state.webpageFeedback);
  const { userData } = useSelector((state) => state.loggedinUser);

  //strings
  const strings = STRINGS['ENGLISH'];

  // Function to toggle comment visibility
  const toggleComment = (event, comment_id) => {
    // event.stopPropagation();
    // event.stopImmediatePropagation();
    // setActiveCommentId(comment_id === activeCommentId ? null : comment_id);

    const comment = commentsData?.find(
      (data) => data.comment_id === comment_id
    );
    setActiveCommentID(comment?.comment_id);
    setActiveComment(comment?.comments);
  };

  useEffect(() => {
    if (highlightComment) {
      setCommentBoxVisible(true);
      setActiveCommentID(highlightComment?.comment_id);
      setActiveComment(highlightComment?.comments);
    }
  }, [highlightComment]);

  const saveComment = () => {
    if (inputComment) {
      const data = {
        webpage_id: webpage_id,
        webpage_version_id: version_id,
        project_id: project_id,
        comments: inputComment,
        is_reply: false,
        ref: 'project',
        ref_id: project_id,
        tagged_usernames: [],
        meta: currentBtnCordinate?.meta,
        created_by_username: userData?.username,
        last_updated_by_username: userData?.username,
        is_resolved: false,
        view_type: viewType,
      };
      dispatch(WebpageCreators.addWebsiteComment(data));
      setInputComment(''); // Clear input field instead of resetting currentBtnCordinate
      setCommentBoxVisible(false);
    }
  };

  const handleCancel = () => {
    setCommentBoxVisible(false);
    // enableScrollingInIframe(); // Call the enableScrollingInIframe function
  };

  const handleDelete = () => {
    deleteComment(activeCommentID, activeComment);
  };

  return (
    <>
      {coordinates?.map(({ comment_id, x, y, newX, newY, comment_no }) => {
        const commentBoxWidth = 250;
        const commentBoxHeight = 140;
        let commentBoxX = newX || x;
        let commentBoxY = newY || y;
        const viewportWidth =
          window.innerWidth || document.documentElement.clientWidth;
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const spaceRight = viewportWidth - (commentBoxX + commentBoxWidth); // Space available to the right of the comment box
        const spaceBottom = viewportHeight - (commentBoxY + commentBoxHeight);
        //CHECK FOR SPACE TO THE RIGHT
        if (spaceRight < 0) {
          commentBoxX = commentBoxX - commentBoxWidth;
        }
        //CHECK FOR SPACE TO THE BOTTOM
        if (spaceBottom < 85) {
          commentBoxY = commentBoxY - commentBoxHeight - 30;
        }
        return (
          <Fragment key={comment_id}>
            <PinButton
              comment_id={comment_id}
              key={comment_no}
              x={(newX - 50) * scaleFactor || (x - 50) * scaleFactor}
              y={(newY - 50) * scaleFactor || (y - 50) * scaleFactor}
              onClick={(event) => toggleComment(event, comment_id)}
            >
              {comment_id ? comment_no : '+'}
            </PinButton>
            {activeCommentID === comment_id && isCommentBoxVisible && (
              <CommentsWrapper
                x={(commentBoxX - 15) * scaleFactor || (x - 15) * scaleFactor}
                y={(commentBoxY - 30) * scaleFactor || (y - 30) * scaleFactor}
              >
                <CommentsInner>
                  <TextDiv
                    onChange={(e) => {
                      setInputComment(e.target.value);
                    }}
                    placeholder={
                      activeComment ? activeComment : strings.ADD_A_COMMENT
                    }
                    disabled={activeComment}
                  />
                  <ButtonRow>
                    {!activeComment && (
                      <Button
                        onClick={() => {
                          saveComment();
                        }}
                        opacity={1}
                        width={'70px'}
                        height={'35px'}
                        noTopMargin
                        marginTop={'0'}
                        type="submit"
                        noBoxShadow={true}
                        disabled={false}
                      >
                        {strings.SAVE}
                      </Button>
                    )}

                    <Button
                      opacity={1}
                      width={'70px'}
                      height={'35px'}
                      marginTop={'0'}
                      noTopMargin
                      type="submit"
                      noBoxShadow={true}
                      disabled={false}
                      bgColor={COLORS.OVERLAY_GREY}
                      onClick={() => {
                        handleDelete();
                      }}
                    >
                      {strings.DELETE}
                    </Button>
                    {activeComment && (
                      <Button
                        opacity={1}
                        width={'70px'}
                        height={'35px'}
                        marginTop={'0'}
                        noTopMargin
                        type="submit"
                        noBoxShadow={true}
                        disabled={false}
                        bgColor={COLORS.OVERLAY_GREY}
                        onClick={() => handleCancel()}
                      >
                        {strings.CLOSE}
                      </Button>
                    )}
                  </ButtonRow>
                </CommentsInner>
              </CommentsWrapper>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default CommentPins;
